import Vue from "vue";
import axios from "axios";
import Router from "vue-router";
import { createRouterLayout } from "vue-router-layout";

import authApi from "@/apis/auth.api";
import helperApi from "@/apis/helper.api";

// Create <RouterLayout> component.
const RouterLayout = createRouterLayout((layout) => {
  // Resolves a layout component with layout type string.
  return import("@/layouts/" + layout + ".vue");
});

Vue.use(Router);

const routes = [
  {
    name: "",
    path: "/",
    component: RouterLayout,
    children: [
      {
        path: "",
        meta: {
          requiresLogin: true,
          userType: ["B2B_USER", "SUPER_USER"],
        },
        component: () => import("@/pages/DashboardB2B.vue"),
      },
    ],
  },
  {
    path: "/login",
    component: RouterLayout,
    children: [
      {
        path: "",
        meta: {
          requiresLogin: false,
          userType: ["B2C_USER", "B2B_USER", "SUPER_USER"],
        },
        component: () => import("@/pages/Login.vue"),
      },
    ],
  },
  {
    path: "/logs",
    component: RouterLayout,
    children: [
      {
        path: "",
        meta: {
          requiresLogin: true,
          userType: ["B2B_USER", "SUPER_USER"],
        },
        component: () => import("@/pages/Logs.vue"),
      },
    ],
  },
  {
    path: "/logs-legacy",
    component: RouterLayout,
    children: [
      {
        path: "",
        meta: {
          requiresLogin: true,
          userType: ["B2B_USER", "SUPER_USER"],
        },
        component: () => import("@/pages/LogsLegacy.vue"),
      },
    ],
  },
  {
    path: "/users",
    component: RouterLayout,
    children: [
      {
        path: "",
        meta: {
          requiresLogin: true,
          userType: ["B2B_USER", "SUPER_USER"],
        },
        component: () => import("@/pages/Users.vue"),
      },
    ],
  },
  {
    path: "*",
    component: RouterLayout,
    children: [
      {
        path: "",
        meta: {
          requiresLogin: false,
          userType: ["B2C_USER", "B2B_USER", "SUPER_USER"],
        },
        component: () => import("@/pages/error/Deny.vue"),
      },
    ],
  },
];
//});

/*function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}*/

const router = new Router({
  //mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const store = Vue.prototype.$store;
  //const expiresAt = await localStorage.getItem("expires_at");

  let userType: never[] = [];
  let requiresLogin = true;
  to.matched.some((record) => {
    requiresLogin =
      record.meta.requiresLogin && record.meta.requiresLogin === true;

    userType = record.meta.userType ?? [];
  });

  let isLoggedIn =
    axios.defaults.headers.common["Authorization"] === undefined ? false : true;

  if (isLoggedIn === false) {
    const accessToken = localStorage.getItem("access_token");
    //console.log("router: accessToken:", accessToken);
    if (accessToken !== null) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
      try {
        const result = await authApi.checkToken();
        console.log("router: authApi.checkToken - result:", result);
        if (result.status === 200) {
          isLoggedIn = true;
          await store.dispatch("app/appInit");
        }
      } catch (err) {
        isLoggedIn = false;
        helperApi.resetToken();
      }
    }
  } else {
    const expiresAt = localStorage.getItem("expires_at");
    //console.log("router: expiresAt:", Number(expiresAt), "now:", Date.now());
    if (expiresAt !== null) {
      if (Date.now() > Number(expiresAt)) {
        isLoggedIn = false;
        helperApi.resetToken();
      }
    }
  }

  if (requiresLogin === true) {
    let loggedUserType = "";
    if (store.getters["app/getUser"]) {
      loggedUserType = store.getters["app/getUser"].userType;
    }
    //console.log("router: loggedUserType:", loggedUserType);
    const pathAuthorized = userType.filter((value) => value === loggedUserType);
    //console.log("router: pathAuthorized:", pathAuthorized);
    if (isLoggedIn === false) {
      next("/login");
    } else {
      //console.log("router: to.path:", to.path);
      if (to.path === "/" && loggedUserType === "B2C_USER") {
        next("/me");
      } else {
        if (pathAuthorized.length === 1) {
          next();
        } else {
          next("/deny");
        }
      }
    }
  } else {
    next();
  }
});

export default router;
