import Vue from "vue";

const events = [
  {
    name: "APP_SESSION_EXPIRED",
    callback: function (app: any) {
      app.snackbar = {
        show: true,
        color: "red",
        text: "Sessione scaduta.",
      };
      app.$router.replace({ path: "/auth" });
    },
  },
  {
    name: "APP_LOADER_START",
    callback: function (app: any, text: string) {
      app.loader = {
        loading: true,
        text: text,
        hide: false,
      };
    },
  },
  {
    name: "APP_LOADER_HIDE_START",
    callback: function (app: any, text: string) {
      app.loader = {
        loading: true,
        text: text,
        hide: true,
      };
    },
  },
  {
    name: "APP_LOADER_DONE",
    callback: function (app: any) {
      app.loader = {
        loading: false,
        text: undefined,
        hide: false,
      };
    },
  },
  {
    name: "APP_ALERT_DONE",
    callback: function (app: any, text: string) {
      app.snackbar = {
        show: true,
        color: "success",
        text: text,
      };
    },
  },
  {
    name: "APP_ALERT_ERROR",
    callback: function (app: any, text: string) {
      app.snackbar = {
        show: true,
        color: "error",
        text: text,
      };
    },
  },
  {
    name: "APP_ALERT_WARNING",
    callback: function (app: any, text: string) {
      app.snackbar = {
        show: true,
        color: "warning",
        text: text,
      };
    },
  },
];

let app: Vue;
class EventHelper {
  setApp(a: Vue) {
    app = a;
    window.logger.debug("eventHelper.setApp: ", app);
  }

  startLoader(text: string) {
    app.$emit("APP_LOADER_START", app, text);
  }

  startLoaderHide(text: string) {
    app.$emit("APP_LOADER_HIDE_START", app, text);
  }

  doneLoader(tag: string) {
    window.logger.debug("eventHelper.done: ", tag);
    setTimeout(() => {
      app.$emit("APP_LOADER_DONE", app);
    }, 300);
  }

  alertError(text: string) {
    app.$emit("APP_ALERT_ERROR", app, text);
  }

  alertSaveError() {
    app.$emit("APP_ALERT_ERROR", app, "Salvataggio non riuscito");
  }

  alertSaveDone() {
    app.$emit("APP_ALERT_DONE", app, "Salvataggio eseguito");
  }

  getEvents() {
    return events;
  }
}

export default new EventHelper();
