import axios from "axios";
import helperApi from "./helper.api";

const baseUrlApi = "/v1/user";

class UserApi {
  async fetchMe() {
    const url = `${baseUrlApi}/me`;
    window.logger.debug("<UserApi.fetchMe>");
    return axios.get(url, {
      headers: helperApi.baseHeaders(),
      responseType: "json",
    });
  }
}

export default new UserApi();
