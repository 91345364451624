import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import logger from "@/utility/logger.helper";
import VueTheMask from "vue-the-mask";
import VueMoment from "vue-moment";

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;

/* eslint-disable */
declare global {
  interface Window { logger: any; publicIp: string; }
}
window.logger = logger;

const moment = require('moment')
require('moment/locale/it')

Vue.use(VueMoment, {
  moment,
})
Vue.prototype.$currency_formatter = new Intl.NumberFormat('it', {
  style: 'currency',
  currency: 'EUR',
})

Vue.use(VueAxios, axios);
Vue.use(VueTheMask)

//console.log('main: store', store);
//console.log('main: logger', logger);

Vue.prototype.$store = store
Vue.prototype.$md5 = require("md5");

Vue.config.errorHandler = function (err: any, vm: any, info: any) {
  logger.debug("handle error - err:", err)
  logger.debug("handle error - vm:", vm)
  logger.debug("handle error - info:", info)
  // handle error
  // `info` is a Vue-specific error info, e.g. which lifecycle hook
  // the error was found in. Only available in 2.2.0+
}

const vueApp = new Vue({
  router,
  store,
  vuetify,
  render: (h: (arg0: any) => any) => h(App)
}).$mount("#app");
