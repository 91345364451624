import userApi from "@/apis/user.api";

interface AppState {
  user: any;
}

const state: AppState = {
  user: null,
};

const mutations = {
  setUser: (state: AppState, value: any) => {
    window.logger.debug("<app.store> setUser", value);
    state.user = value;
  },
};

const getters = {
  getUser: (state: AppState) => state.user,
  isOperator: (state: AppState) => {
    if (state.user !== null) {
      if (
        state.user.userType === "SUPER_USER" ||
        state.user.userType === "B2B_USER"
      ) {
        return true;
      }
    }
    return false;
  },
  isSuperUser: (state: AppState) => {
    if (state.user !== null) {
      if (state.user.userType === "SUPER_USER") {
        return true;
      }
    }
    return false;
  },
};

const actions = {
  appInit: async (context: any) => {
    window.logger.debug("<app.store> appInit");

    context.commit("setUser", null);

    await userApi.fetchMe().then((response) => {
      window.logger.debug("fetchMe response", response.data);
      context.commit("setUser", response.data);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
