import axios from "axios";
import ipifyApi from "./ipify.api";
import helperApi from "./helper.api";

const VUE_APP_API_KEY = process.env.VUE_APP_API_KEY;

const baseUrlApi = "/v1/version";
const baseUrlApiOauth = "/authenticate";

class AuthApi {
  async fetchRefreshToken(request: JSON) {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      "X-public-ip": window.publicIp,
      "x-api-key": VUE_APP_API_KEY,
    };

    return axios.post(baseUrlApiOauth, request, {
      headers: headers,
      responseType: "json",
    });
  }

  async fetchTokenUsingPassword(request: JSON) {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "cache-control": "no-cache",
      "X-public-ip": window.publicIp,
      "x-api-key": VUE_APP_API_KEY,
    };

    return axios.post(baseUrlApiOauth, request, {
      headers: headers,
      responseType: "json",
    });
  }

  async checkToken() {
    const url = `${baseUrlApi}`;
    window.logger.debug("<AuthApi.checkToken>");
    return axios.post(
      url,
      { headers: helperApi.baseHeaders(), responseType: "json" },
      {}
    );
  }
}

export default new AuthApi();
