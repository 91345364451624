class IpifyApiStatic {
  retrivePublicIP() {
    let rv = "";
    const request = new XMLHttpRequest();
    request.open("GET", "https://api.ipify.org?format=json", false);
    request.send(null);
    if (request.status === 200) {
      rv = JSON.parse(request.responseText).ip;
    }
    return rv;
  }
}

export default new IpifyApiStatic();
