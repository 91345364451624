const isLoggerEnabled = process.env.VUE_APP_LOGGER_ENABLE === "true";

class LoggerHelper {
  debug(...args: any[]) {
    if (isLoggerEnabled === true) {
      console.debug(...args);
    }
  }
  info(...args: any[]) {
    if (isLoggerEnabled === true) {
      console.info(...args);
    }
  }
  warn(...args: any[]) {
    if (isLoggerEnabled === true) {
      console.warn(...args);
    }
  }
  error(...args: any[]) {
    if (isLoggerEnabled === true) {
      console.error(...args);
    }
  }
}

export default new LoggerHelper();
