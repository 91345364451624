import axios from "axios";
import ipifyApi from "@/apis/ipify.api";

class HelperApi {
  baseContent() {
    const content = {};
    return content;
  }

  formHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-public-ip": window.publicIp,
    };
  }

  multipartFormHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "cache-control": "no-cache",
        "X-public-ip": window.publicIp,
      },
      responseType: "json",
    };
    return config;
  }

  baseHeadersCache() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-public-ip": window.publicIp,
    };
  }

  baseHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    return {
      Accept: "application/json",
      "cache-control": "no-cache",
      "Content-Type": "application/json",
      "X-public-ip": window.publicIp,
    };
  }

  blobHeaders() {
    if (window.publicIp === "") {
      window.publicIp = ipifyApi.retrivePublicIP();
    }
    const config = {
      headers: {
        "X-public-ip": window.publicIp,
      },
      responseType: "blob",
    };
    return config;
  }

  setToken(data: { accessToken: string; expiresIn: number }) {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + data.accessToken;
    const expiresAt = Date.now() + data.expiresIn * 1000;
    localStorage.setItem("access_token", data.accessToken);
    localStorage.setItem("expires_at", expiresAt.toString());

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          window.location.href = "/";
        }
      }
    );
  }

  resetToken() {
    axios.defaults.headers.common["Authorization"] = null;
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
  }
}

export default new HelperApi();
